import axios from '@axios'

export default {
  namespaced: true,
  state: {
    user: {},

  },
  getters: {},
  terms: {},
  mutations: {

    GET_USER(state, payload) {
      state.user = payload
    },
    SET_TERMS(state, payload) {
      state.terms = payload
    },

  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addUser(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('users', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    addGenerateCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('code-generator', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    HomeSection(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('home-sections', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    GenerateDynaimcMap(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('generate-dynamic-site-map', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    copyProtection(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('copy-protection', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    GetHomeSection() {
      return new Promise((resolve, reject) => {
        axios
          .get('home-sections')
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    GetcopyProtectiondata() {
      return new Promise((resolve, reject) => {
        axios
          .get('copy-protection')
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    getConslutingService() {
      return new Promise((resolve, reject) => {
        axios
          .get('consulting-service')
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    getBecomeInstructor() {
      return new Promise((resolve, reject) => {
        axios
          .get('become-instructor')
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    getAccerditedPage() {
      return new Promise((resolve, reject) => {
        axios
          .get('accerdited-page')
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    addBecomeInstructor(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('become-instructor', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    addAccerditedPage(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('accerdited-page', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    getCountryPage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`country-pages/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getNgos(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`ngos/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    getFaq(ctx, { id, countryPage }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`country-pages/${countryPage}/faqs/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getFaqNgo(ctx, { id, ngos }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`ngos/${ngos}/faqs/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getProjectNgo(ctx, { id, ngoId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`ngos/${ngoId}/projects/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getLogoNgo(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`ngo-logos/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateNgos(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`ngos/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updatefaq(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`country-pages/${payload.CountryPageId}/faqs/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatefaqNgo(ctx, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axios
          .post(`ngos/${payload.NgoId}/faqs/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProjectNgo(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`ngos/${payload.NgoId}/projects/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateLogoNgo(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`ngo-logos/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFaq(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`country-pages/${payload.id}/faqs`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFaqNgo(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`ngos/${payload.id}/faqs`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addNgosLogo(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`ngo-logos`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addNgosProject(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`ngos/${payload.id}/projects`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addNgosPrice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('ngo-prices', payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addNgosFullDiscount(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`ngos-full-discount/${ payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCountryPageFullDiscount(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`country-pages-full-discount/${ payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addNgosLongPrice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('ngo-diploma-prices', payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateNgosPrice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`ngo-prices/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateNgosFullDiscount(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`ngos-full-discount/${payload.ngoId}/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCountryPageFullDiscount(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`country-pages-full-discount/${payload.ngoId}/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateNgosLongPrice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`ngo-diploma-prices/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getNgosPrice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`ngo-prices/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getNgospercentage(ctx, { id, ngoId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`ngos-full-discount/${ngoId}/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getCountryPagepercentage(ctx, { id, ngoId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`country-pages-full-discount/${ngoId}/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getNgosLongPrice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`ngo-diploma-prices/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    allNgosPrice(ctx,queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`get-ngo-prices/${queryParams.id}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    allNgosFullDiscount(ctx,queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`ngos-full-discount/${queryParams.id}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    allCountryPageDiscount(ctx,queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`country-pages-full-discount/${queryParams.id}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    allNgosScheduleCourse(ctx,payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`ngos-schedules/${payload.id}`, { params: payload.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    allCountryPageScheduleCourse(ctx,payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`country-pages-schedules/${payload.id}`, { params: payload.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    allNgosOtherScheduleCourse(ctx,payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`ngos-long-schedules/${payload.id}`, { params: payload.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    allNgosLongScheduleCourse(ctx,payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`ngos-long-schedules/${payload.id}`, { params: payload.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    allCountryLongScheduleCourse(ctx,payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`country-pages-long-schedules/${payload.id}`, { params: payload.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    allNgosLongPrice(ctx,queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`get-ngo-diploma-prices/${queryParams.id}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addScheduleCountryPages(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`country-pages-schedules/${payload.id}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addScheduleNgos(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`ngos-schedules/${payload.id}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addScheduleNgosOther(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`ngos-other-schedules/${payload.id}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addScheduleNgosLong(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`ngos-long-schedules/${payload.id}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addScheduleCountryPagesLongCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`country-pages-long-schedules/${payload.id}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addScheduleNgoLongCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`ngos-long-schedules/${payload.id}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCountryPage(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('country-pages', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    updateCountryPage(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`country-pages/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    addNgos(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('ngos', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    addConslutingService(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('consulting-service', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    GetSettingArray(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('settings-array', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    updateSettingArray(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('settings-array-update', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    allCodeGenertor() {
      return new Promise((resolve, reject) => {
        axios
          .get('code-generator')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AllCountryPages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('country-pages', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AllNgos(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('ngos', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addDuration(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('durations', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    getDuration(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('durations', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AllFaq(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`country-pages/${queryParams.id}/faqs`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AllFaqNgo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`ngos/${queryParams.id}/faqs`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AllProjectNgo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`ngos/${queryParams.id}/projects`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AllLogoNgo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`ngo-logos`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GenerateMap() {
      return new Promise((resolve, reject) => {
        axios
          .post('generate-site-map')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteGenerateCode(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`code-generator/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteCountryPage(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`country-pages/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteCountryPageFaq(ctx, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axios
          .delete(`country-pages/${payload.id}/faqs/${payload.FaqId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteNgoFaq(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`ngos/${payload.id}/faqs/${payload.FaqId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteNgoProject(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`ngos/${payload.id}/projects/${payload.projectId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteNgoLogo(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`ngo-logos/${payload.projectId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteNgoFullDiscount(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`ngos-full-discount/${payload.id}/${payload.projectId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteCountryPageFullDiscount(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`country-pages-full-discount/${payload.id}/${payload.projectId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteNgoLongPrice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`ngo-diploma-prices/${payload.projectId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteNgoPrice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`ngo-prices/${payload.projectId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // DeleteFaq(ctx, id) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .delete(`country-pages/${id}`)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    DeleteNgos(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`ngos/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteNgosScheduleCorse(ctx, {id,schduleId}) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`ngos-schedules/${id}/${schduleId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteCountryPageScheduleCorse(ctx, {id,schduleId}) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`country-pages-schedules/${id}/${schduleId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteNgosOtherScheduleCorse(ctx, {id,schduleId}) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`ngos-schedules/${id}/${schduleId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteNgosLongScheduleCorse(ctx, {id,schduleId}) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`ngos-long-schedules/${id}/${schduleId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteCountryLongScheduleCorse(ctx, {id,schduleId}) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`country-pages-long-schedules/${id}/${schduleId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AllUser(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateUser(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`users/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteUser(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteDuration(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`durations/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`users/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addLinkRederication(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('redirections', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    addLinkRedericationArabic(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('redirection-to-arabics', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    getAllRediraction(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('redirections', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllRediractionArabic(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('redirection-to-arabics', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteLink(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`redirections/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    AllRoles() {
      return new Promise((resolve, reject) => {
        axios
          .get('roles')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getTerms() {
      return new Promise((resolve, reject) => {
        axios
          .get('terms')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getFOOTER() {
      return new Promise((resolve, reject) => {
        axios
          .get('terms')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTerms(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('terms', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateQualityPolice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('quality-policy', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getQualityPolice() {
      return new Promise((resolve, reject) => {
        axios
          .get('quality-policy')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePrivacyPolice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('privacy-policy', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPrivacyPolice() {
      return new Promise((resolve, reject) => {
        axios
          .get('privacy-policy')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateInfo(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('update-profile', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getInfo() {
      return new Promise((resolve, reject) => {
        axios
          .get('update-profile')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getStatsitic() {
      return new Promise((resolve, reject) => {
        axios
          .get('get-general-counts')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getStatsiticSchema() {
      return new Promise((resolve, reject) => {
        axios
          .get('/schemas-counts')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdatePassowrd(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('change-password', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },

}
